import React, { ReactNode, useEffect } from 'react'
import { Theme } from '@liquid-design/liquid-design-react'
import ReactGA from 'react-ga'
import { useLocation } from '@reach/router'
import { isDev } from 'utils/env'
// import { isDev, isStaging, isProd } from 'utils/env'
import Resolution from 'components/development/Resolution/Resolution'
import DevGrid from 'components/development/DevGrid/DevGrid'
// import VWO from 'utils/vwo'

import '../../tailwind.generated.css'
import '../../assets/styles/index.css'

interface Props {
  children: ReactNode
}

const LayoutBase = ({ children }: Props) => {
  // Track page views.
  const location = useLocation()
  useEffect(() => {
    if (typeof location === 'undefined') return
    let pathname = location.pathname
    // Remove <publication_id> part from /publication/<publication_id>
    if (pathname.indexOf('/publication/') === 0) {
      pathname = '/publication/'
    }
    ReactGA.pageview(pathname)
  }, [location])

  return (
    <>
      {isDev && <Resolution />}
      {isDev && <DevGrid />}
      {/*{(isStaging || isProd) && <VWO />}*/}

      <Theme themeName="richBlue">{children}</Theme>
    </>
  )
}

export default LayoutBase
