import React, { ReactNode } from 'react'

import './Section.css'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
  children?: ReactNode
}

const Section: React.FC<Props> = (props) => {
  const cn = `${props.className || ''} section`
  return (
    <section className={cn}>
      <div className="section__content mx-auto md:px-10">
        <div className="px-5 md:px-2.5">{props.children}</div>
      </div>
    </section>
  )
}

export default Section
