import React from 'react'
import './Shapes.css'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
  variant?: number
}

const Shapes: React.FC<Props> = (props) => {
  const cn = `${
    props.className || ''
  } shapes w-full absolute top-0 overflow-hidden pointer-events-none`
  let cnShape = `shape opacity-0`
  switch (props.variant) {
    case 2:
      cnShape += ' shape--variant-2'
      break
    default:
      cnShape += ' shape--variant-1'
  }
  return (
    <div className={cn}>
      <div className={`${cnShape} shape--left`} />
      <div className={`${cnShape} shape--right`} />
    </div>
  )
}

export default Shapes
