import React from 'react'
import { Link } from 'gatsby'
import InitialMComponent from 'assets/svgs/m.svg'

interface Props {
  className?: string
  path?: string
  dark?: boolean
  color?: string
}

const Logo: React.FC<Props> = ({ className, path, dark, color }) => {
  const InitialM = InitialMComponent as any

  return (
    <Link
      to={path || '/'}
      className={`inline-flex items-center ${className || ''}`}
    >
      <InitialM
        className={`${
          color ? 'text-' + color : 'text-vibrant-yellow'
        } fill-current mr-3 flex-shrink-0`}
      />
      <span
        className={`${
          dark ? 'text-rich-blue-darkest' : 'text-white'
        } uppercase text-s`}
      >
        <span className="font-extrabold">Knora</span>Gene
      </span>
    </Link>
  )
}

export default Logo
