import React, { ReactNode } from 'react'
import Logo from 'components/Logo/Logo'
import Section from 'components/LandingPage/Section/Section'

import './Header.css'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
  children?: ReactNode
  noLogin?: boolean
}

const Header: React.FC<Props> = (props) => {
  const cn = `${props.className || ' header section py-8'}`
  return (
    <header className={cn}>
      <Section>
        <div className="flex justify-between items-center">
          <Logo dark color="vibrant-cyan" className="" />
          {props.noLogin ? (
            ''
          ) : (
            <button
              onClick={() => {
                window.location.pathname = '/database/'
              }}
              data-test="btn-login"
              className="header__btn-login px-5 py-2.5"
            >
              Login
            </button>
          )}
        </div>
      </Section>
    </header>
  )
}

export default Header
