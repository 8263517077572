import React from 'react'
import './Resolution.css'

const breakpoints = [
  { key: 'xxxs', value: 1 },
  { key: 'sm', value: 640 - 1 },
  { key: 'md', value: 768 - 1 },
  { key: 'lg', value: 1024 - 1 },
  { key: 'xl', value: 1280 - 1 },
  { key: '2xl', value: 1536 - 1 },
]

export default function Resolution(): JSX.Element {
  return (
    <div className="resolution">
      {breakpoints.map((bp, index) => (
        <div
          key={bp.key}
          className={`resolution__${bp.key}`}
          style={{
            width: `${bp.value + 1}px`,
            display: index === 0 ? 'none' : undefined,
          }}
        >
          <b>{bp.key.toUpperCase()}</b> ({bp.value + 1}px)
        </div>
      ))}
    </div>
  )
}
