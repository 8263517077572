import React, { useState } from 'react'

import './DevGrid.css'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const DevGrid: React.FC<Props> = (props) => {
  const cn = `${
    props.className ||
    'dev-grid fixed w-full h-screen inset-0 pointer-events-none'
  }`

  const [isVisible, setIsVisible] = useState<boolean>(false)

  const toggleGrid = () => {
    setIsVisible(!isVisible)
  }

  return (
    <div className={cn}>
      <div
        className="dev-grid__toggle fixed pointer-events-auto p-1/2rem"
        onClick={toggleGrid}
      >
        ||||
      </div>
      <div
        className={`${
          isVisible ? '' : 'invisible'
        } dev-grid__container mx-auto h-full`}
      >
        <div className="container__content mx-2.5 md:mx-10 h-full flex">
          <div className="dev-grid__indicator flex-1 px-2.5"></div>
          <div className="dev-grid__indicator flex-1 px-2.5"></div>
          <div className="dev-grid__indicator flex-1 hidden px-2.5 md:flex"></div>
          <div className="dev-grid__indicator flex-1 hidden px-2.5 md:flex"></div>
          <div className="dev-grid__indicator flex-1 hidden px-2.5 md:flex"></div>
          <div className="dev-grid__indicator flex-1 hidden px-2.5 md:flex"></div>
        </div>
      </div>
    </div>
  )
}

export default DevGrid
