import React from 'react'
import { Link } from 'gatsby'
import Section from '../LandingPage/Section/Section'
import { Headline, Button, Theme } from '@liquid-design/liquid-design-react'
import Chevron from './assets/chevron.svg'
import Logo from '../Logo/Logo'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
  compact?: boolean
}

const Footer: React.FC<Props> = (props) => {
  const cn = `${props.className || ''} bg-rich-blue-darkest text-white relative`

  const ChevronIcon = Chevron as any

  return (
    <div className={cn}>
      <Section>
        {props.compact ? (
          ''
        ) : (
          <div className="md:flex justify-between pt-16">
            <Headline type="H2" className="pb-12 max-w-xs">
              Extracting genes has never&nbsp;been&nbsp;easier.
            </Headline>
            <div className="pb-10">
              <Theme themeName="vibrantCyan">
                <Link to="/database">
                  <Button
                    size="big"
                    appearance="ghost"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <span style={{ marginLeft: '-5px' }}>
                      Start your first extractoin
                    </span>
                    <ChevronIcon
                      style={{ marginRight: '-10px' }}
                      className="inline-flex ml-4"
                    />
                  </Button>
                </Link>
              </Theme>
            </div>
          </div>
        )}

        {props.compact ? '' : <hr className="opacity-20" />}

        <div className="lg:flex justify-between pt-14 pb-6">
          <div className={`sm:flex ${props.compact ? '' : 'pb-10'}`}>
            <div className="mb-8">
              <Logo className="mr-12" />
            </div>
            <div className="mb-4">
              <Link className="mr-12" to="/privacy">
                Data Privacy
              </Link>
            </div>
            <div className="mb-4">
              <Link className="mr-12" to="/terms">
                Terms and Conditions
              </Link>
            </div>
            <div className="mb-4">
              <Link className="mr-12" to="/imprint">
                Imprint
              </Link>
            </div>
          </div>
          <div className="pb-10">
            © Merck KGaA, Darmstadt, Germany,{' '}
            {new Date().getFullYear().toString()}
          </div>
        </div>
      </Section>
    </div>
  )
}

export default Footer
