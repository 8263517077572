import React from 'react'
import { Headline } from '@liquid-design/liquid-design-react'
import LayoutLandingPage from 'layouts/LayoutLandingPage/LayoutLandingPage'
import Header from 'components/LandingPage/Header/Header'
import Section from 'components/LandingPage/Section/Section'
import Shapes from 'components/Shapes/Shapes'
import NotFoundIconComponent from 'assets/svgs/not-found.svg'

export default function Page404() {
  const NotFoundIcon = NotFoundIconComponent as any

  return (
    <LayoutLandingPage compact={true} className="h-screen flex flex-col">
      <Header />
      <Section className="text-center">
        <NotFoundIcon className="mx-auto max-w-full h-auto" />
        <Headline type="H1" className="pb-16">
          404. This page does not exist.
        </Headline>
      </Section>
      <Shapes variant={2} />
    </LayoutLandingPage>
  )
}
