import React, { ReactNode } from 'react'
import LayoutBase from '../LayoutBase/LayoutBase'
import Footer from 'components/Footer/Footer'

import './LayoutLandingPage.css'

interface Props {
  children: ReactNode
  className?: string
  compact?: boolean
}

const LayoutLandingPage = ({ children, className, compact }: Props) => {
  const cn = `${
    className || ''
  } layout-landing-page w-full text-rich-blue-darkest text-sm`
  return (
    <LayoutBase>
      <div style={{ minWidth: '20rem' }} className={cn}>
        <main
          className="relative overflow-hidden flex flex-col flex-grow flex-shrink-0"
          id="main"
        >
          {children}
        </main>
        <Footer compact={compact} />
      </div>
    </LayoutBase>
  )
}

export default LayoutLandingPage
